<script>

  import "@vueform/multiselect/themes/default.css";

  import {
    required,
    helpers
  } from "@vuelidate/validators";
  import useVuelidate from "@vuelidate/core";
  import { mapActions } from "vuex";


  import appConfig from "../../../../app.config";
  import Swal from "sweetalert2";
  import axios from 'axios';
  //import animationData from "@/components/widgets/msoeawqm.json";
  //import animationData1 from "@/components/widgets/gsqxdxog.json";
  //import Lottie from "@/components/widgets/lottie.vue";
  import moment from 'moment'
  import serverConfig from '@/helpers/config';
  
  
  export default {
    name:'TableFleet',
    setup() {
      moment.locale('it')
    
    
      let tk = 'Bearer '+localStorage.getItem('tk')


      return { tk, v$: useVuelidate()};
    },
    page: {
      title: "Lista Mezzi",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        title: "Lista Mezzi",
        items: [{
            text: "Mezzi",
            href: "/fleet/list-view",
          },
          {
            text: "Lista",
            active: true,
          },
        ],
        date3: null,
        rangeDateconfig: {
          wrap: true, // set wrap to true only when using 'input-group'
          altFormat: "M j, Y",
          altInput: true,
          dateFormat: "d M, Y",
          mode: "range",
        },
        config: {
          wrap: true, // set wrap to true only when using 'input-group'
          altFormat: "M j, Y",
          altInput: true,
          dateFormat: "d M, Y",
        },
        timeConfig: {
          enableTime: false,
          dateFormat: "d M, Y",
        },
        filterdate: null,
        filterdate1: null,
        filtervalue: 'All',
        filtervalue1: 'All',
        filtersearchQuery1:null,
        date: null,
        submitted: false,
        regError: null,
        tryingToRegister: false,
        isRegisterError: false,
        registerSuccess: false,
        item:{
          name:"",
          details:"",
          ownership:false,
          ownershipDesc:'',
          manufacturer:'',
          manufacturerDesc:'',
          kmStart:'',
          createdBy:this.$store.state.auth.currentUser,
        },
        optionsFiscalBenefit:[],
        optionsFSPrivate:[
          { value: '', label: 'Seleziona beneficio fiscale' },
          { value: 'conto_termico', label: 'Conto termico' },
          { value: 'detrazione_fiscale', label: 'Detrazione fiscale' },
        ],
        optionsFSBusiness:[
          { value: '', label: 'Seleziona beneficio fiscale' },
          { value: 'credito_imposta', label: "Credito d'imposta" },
          { value: 'sconto_in_fattura', label: 'Sconto in fattura' },
        ],
        optionsFSPA:[
          { value: 'non_previsto', label: 'Non previsto' },
        ],
        allTask: [
        ],
        searchQuery: null,
        page: 1,
        perPage: 50,
        sortRes:'asc',
        value: 'All',
        pages: [],
        responseList: [
        ],
        paginated:{},
        select:'all',
        search:'',
        defaultOptions: {
          animationData: []
        },
        defaultOptions1: {
          animationData: []
        },
        form:{
          start:'',
          end:'',
          status:'init',
          select:'',
        },
        n_results:0,
        minDate:moment().format('YYYY-MM-DD HH:MM'),
        maxDate:new Date().getFullYear().toString() + '-12-31 23:59',
        minDateEnd:moment().format('YYYY-MM-DD HH:MM'),
        maxDateEnd:new Date().getFullYear().toString() + '-12-31 23:59',
        dataEdit:{},
        vehicle:{
          name:"",
          details:"",
          ownership:false,
          ownershipDesc:'',
          manufacturer:'',
          manufacturerDesc:'',
          kmStart:'',
          createdBy:this.$store.state.auth.currentUser,
        },
      };
    },
    components: {
     // lottie: Lottie,
    },
    validations: {
      item: {
        source: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        customerCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemSubCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        fiscalBenefit: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
      },
    },
    computed: {
      
    },
    watch: {
      allTask() {
        this.setPages();
      },
    },
    created() {
      this.setPages();
    },
    filters: {
      trimWords(value) {
        return value.split(" ").splice(0, 20).join(" ") + "...";
      },
    },
    beforeMount() {
      this.getList()
    },

    methods: {
      convertDate(date){
        if(date) {
          return moment(date).format('DD/MM/YYYY HH:MM')
        }
      },
      updateDate(type){
        if (type=='start'){
          this.minDateEnd = this.form.start
          this.form.end = this.form.start
        }
        if (type=='end'){
          this.minDateEnd = this.form.start
        }
      },
      ...mapActions('layout', ['changeLayoutType', ]),
    
      axiosInterceptor(){
        axios.interceptors.response.use(
          (response) => {
            return response;
          },
          (error) => {
              if (error.response && error.response.status === 401) {
                Swal.fire({
                  title:"Accesso non autorizzato",
                  text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                  icon:"warning",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                  this.$router.push('/login')
                  if(localStorage.getItem('tk')){
                    localStorage.removeItem('tk')
                  }
                  return Promise.reject('Unauthorized access');
              } else if (error.response && error.response.status  === 404) {
                  Swal.fire({
                    title:"Nessun risultato trovato",
                    text:"Mi spiace, nessun risultato trovato",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              }  else if (error.response && error.response.status  === 412) {
                Swal.fire({
                  title:"Credenziali mancanti",
                  text:"Inserisci delle credenziali valide, per favore.",
                  icon:"warning",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            } else if (error.response && error.response.status  === 423) {
                  Swal.fire({
                    title:"Risorsa non accessibile",
                    text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  this.$router.push('/login')
                  if(localStorage.getItem('tk')){
                    localStorage.removeItem('tk')
                  }                
                  return Promise.reject('The server cannot find the requested resource');
              }
              else if (error.response && error.response.status  === 500) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                  return Promise.reject('Internal Server Error');
              }
              else if (error.response && error.response.status  === 502) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('Bad Gateway');
              }
              else if (error.response && error.response.status  === 503) {
                Swal.fire({
                  title:"Servizio non disponibile",
                  text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                  return Promise.reject('Service Unavailable');
              } else {
                console.log(error.response.status )
                Swal.fire({
                  title:"Errore imprevisto",
                  text:"Ops! si è verificato un problema...riprova, per favore.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                
              }

          }
        );
        
      },
      getList(){
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}vehicles/per-page/all`, 
          { 
            params: { 
              perPage: this.perPage,
              page: this.page,
              sortRes: this.sortRes,
              select: this.select,
              search: this.search,
            }, 
            headers: { 'authorization':this.tk}
          }
        ).then(response=>{
          this.responseList = response.data.results.data;
          this.paginated = response.data.results
          this.n_results = response.data.n_results
          })
      },
      SearchData(){
        if (this.form.start!='' && this.form.end!=''){
          this.axiosInterceptor()
          axios.get(`${this.UrlServer}vehicles/filter/all`, 
            { 
              params: { 
                perPage: this.perPage,
                page: this.page,
                sortRes: this.sortRes,
                select: this.select,
                search: this.search,
                start:moment(this.form.start).unix(),
                end:moment(this.form.end).unix(),
                status:this.form.status,
              }, 
              headers: { 'authorization':this.tk}
            }
          ).then(response=>{
            this.responseList = response.data.results.data;
            this.paginated = response.data.results
            this.n_results = response.data.n_results
            })
        }
      },
      editdata(id) {
        this.$router.push('/fleet/detail/'+ id)
      },
      initItem(){
        this.submitted = true;
        this.v$.$touch()
        this.axiosInterceptor()
        axios.post(`${this.UrlServer}vehicles/register`, this.item, {headers: { authorization:this.tk}} )
          .then((response)=>{
            if (response.data) {
              alert(JSON.stringify(response.data))
              Swal.fire({  
                title: this.$t("t-msg-vehicle-init") , 
                text: this.$t("t-msg-vehicle-init-content"), 
                icon:"success",              
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000
              }).then(()=>{
                this.$router.push('/fleet/detail/'+response.data)
              });
            }
        })
      },
      deletedata(id){
       
        Swal.fire(
          {
            title: this.$t("t-delete-confirm-setting-title"),
            text: this.$t("t-delete-confirm-setting-text"),
            icon: this.$t("t-delete-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-delete-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-delete-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-delete-confirm-setting-confirmButtonColor"),
            confirmButtonText: this.$t("t-delete-confirm-setting-confirmButtonText")
          }
          ).then((result) => {
            if (result.value) {
              this.axiosInterceptor()
              axios.delete(`${this.UrlServer}vehicles/${id}`, {headers: { authorization:this.tk}} )
                .then((response)=>{
                    if (response.data) {
                      Swal.fire({
                        title:this.$t("t-msg-deleted"),
                        text:this.$t("t-msg-data-deleted"),
                        icon:"success",
                        showCancelButton:false,
                        showConfirmButton:false,
                        timer:2000,
                      })
                    }
                  this.getList() 
              })
            }
        });
      },
      setPages() {
        let numberOfPages = Math.ceil(this.allTask.length / this.perPage);
        this.pages = [];
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },
      paginate(customerList) {
        let page = this.page;
        let perPage = this.perPage;
        let from = page * perPage - perPage;
        let to = page * perPage;
        return customerList.slice(from, to);
      },
      paginateAct(pg){
        this.page = pg
        this.getList()
      },
      splitStr(str,car){
        if (str){
          if(str.includes(car)==true) {
            let val =  str.split(car)
            return `${val[0]} ${val[1]}`
          } else{
            return str
          }
        } else {
          return ''
        }
    }
    },
    mounted() {

    }
  };
</script>

<template>
 
    
<!-- Modulo tabella elenco progetti/cantieri -->    
    <div class="row">
      <div class="col-lg-12">
        <div class="card" id="tasksList">
          <div class="card-header border-0">
            <div class="d-flex align-items-center">
              <h5 class="card-title mb-0 flex-grow-1">Flotta</h5>
              <div class="flex-shrink-0">
               
              </div>
            </div>
          </div>
      
          <div class="card-body border border-dashed border-end-0 border-start-0">
            <div class="mb-50">
              <div class="row rowSearchFormBGce g-4 mb-50">

                  <div class="col-lg-5 col-md-12">
                    <div class="">
                      <div class="search-box ms-2">
                        <input type="text" class="form-control" placeholder="Cerca mezzo..." v-model="search" @input="getList()" />
                        <i class="ri-search-line search-icon"></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-12">
                    <div class="">
                      <input type="date"  :placeholder="$t('t-from')"   class="form-control" v-model="form.start" :min="minDate" :max="maxDate" @change="updateDate('start')">
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-12">
                    <div class="">
                      <input type="date"  :placeholder="$t('t-to')"   class="form-control" v-model="form.end" :min="minDateEnd" :max="maxDateEnd"  @change="updateDate('end')" >
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-12">
                    <select class="form-select" v-model="form.status">
                       <option value="all">Tutti</option>
                       <option value="init">Non assegnati</option>
                       <option value="assigned">Assegnati</option>
                       <option value="pending">Lavorazione in corso</option>
                       <option value="blocked">Bloccati</option>
                       <option value="canceled">Annullati</option>
                       <option value="completed">Completati</option>
                    </select>

                  </div>
                  <div class="col-lg-1 col-md-12">
                    <button type="button" class="btn btn-primary w-100" @click="SearchData">
                      <i class="ri-equalizer-fill me-1 align-bottom"></i>
                      {{ $t('t-filters') }}
                    </button>
                  </div>
              </div>
            </div>
            <div class="row ">
                <div class="col-lg-12">         
                    <div class="card-body pl-10 mb-20">
                        <div style="float: right;">
                          <b-button variant="outline-primary" class="waves-effect waves-light" @click="initItem">
                            <i class="mdi mdi-plus"></i>
                            <i class="mdi mdi-car-pickup"></i>
                            Crea anagrafica mezzo
                          </b-button>
                        </div>
                    </div>
                </div>
              </div>
            <div class="card-header  mb-50">

              <div class="row align-items-center mb-50">
                <div class="col ">
                  <ul class="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active fw-semibold" data-bs-toggle="tab" href="#productnav-all" role="tab">
                        
                        Mezzi registrati
                        <span class="badge badge-soft-danger align-middle rounded-pill ms-1">{{n_results}}</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-auto">
                  <div id="selection-element">

                  </div>
                </div>
              </div>
            </div>
            <div class="table-card mt-50">
                <div class="row thRow mt-50">
                    <div class="col text-left">
                      Nome
                    </div>
                    <div class="col text-left">
                      Marca
                    </div>
                    <div class="col text-left">
                      Proprietà
                    </div>
                    <div class="col text-left">
                      KM Iniziali
                    </div>
                    <div class="col text-left">
                      Disponibilità
                    </div>
                    <div class="col-lg-1 col-md-12 text-left">
                      Azioni
                    </div>
                </div>
              <div class="row list tRow form-check-all d-flex" v-for="(item, index) of responseList" :key="index">
                <div class="col text-left">
                      {{ item.name}}
                    </div>
                    <div class="col text-left">
                        {{ item.manufacturer }}
                    </div>
                    
                    <div class="col text-left">
                        {{ item.ownershipDesc }}
                    </div>
                    <div class="col text-left">
                        {{ item.kmEnd }}
                    </div>
                    <div class="col text-left">
                        N.D.
                    </div>
                    <div class="col-lg-1 col-md-12 text-left">
                        <ul class="list-inline text-left">
                          <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Modifica">
                              <a href="javascript:void(0)" data-bs-toggle="modal"
                                class="text-primary d-inline-block edit-item-btn" @click="editdata(item._id)">
                                <i class="ri-pencil-fill fs-16"></i>
                              </a>
                            </li>
                            <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Rimuovi">
                              <a href="javascript:void(0)" class="text-danger d-inline-block remove-item-btn" @click="deletedata(item._id)">
                                <i class="ri-delete-bin-5-fill fs-16"></i>
                              </a>
                            </li>
                        </ul>
                    </div>
                </div>
              
            </div>
            <div>
              <div class="table-responsive table-card mb-1">

                <div class="noresult" style="display: none">
                  <div class="text-center">
                    <!--
                    <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
                      :height="75" :width="75" />-->                 
                    <h5 class="mt-2">{{ $t("t-msg-sorry-no-result") }}</h5>
                    <p class="text-muted mb-0">
                     
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-flex tRow justify-content-end mt-3">        
                    <div class="col-lg-3 col-md-2 ">
                        <label class="pt-2">Risultati per pagina:</label>
                        <div class="pl-10 ">
                        
                        <select class="form-select" style="width: 100px;" v-model="perPage" @change="paginateAct(paginated.pre_page)">
                        <option v-for="item in [5,10,20,50,100]" :key="item" :value="item">{{ item}}</option>
                        </select>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-12 ">
                        <div class="pagination-wrap hstack gap-2 pt-25">
                        <a class="page-item pagination-prev disabled" href="#" v-if="paginated.pre_page > 1" @click="paginateAct(paginated.pre_page)">
                            Precedenti
                        </a>
                        <ul class="pagination listjs-pagination mb-0" >
                            <li :class="{
                                    active: index == page,
                                    disabled: index == '...',
                                    }" v-for="index in paginated.total_pages" :key="index">
                                <a class="page" href="#" @click="paginateAct(index)">{{ index}}</a>
                            </li>
                        
                        </ul>
                        <a class="page-item pagination-next" href="#" @click="paginateAct(paginated.next_page)" >
                        Successivi
                        </a>
                        </div>
                    </div>
              </div>
            </div>
           
          </div>

        </div>
        <!--end card-->
      </div>
      <!--end col-->

      
  
    </div>
  <!--end row-->
</template>